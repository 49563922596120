import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout";
import Seo from "../components/seo";

// styles

const paragraphStyles = {
    marginBottom: 48,
}
const codeStyles = {
    color: "#8A6534",
    padding: 4,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
}

// markup
const NotFoundPage = () => {
    return (
        <Layout>
            <Seo title="Not found"/>
            <div className="w-full px-8">
                <h2 className="item-title">Page not found</h2>
                <p style={paragraphStyles}>
                    Sorry{" "}
                    <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
                    we couldn’t find what you were looking for.
                    <br/>
                    {process.env.NODE_ENV === "development" ? (
                        <>
                            <br/>
                            Try creating a page in <code style={codeStyles}>src/pages/</code>.
                            <br/>
                        </>
                    ) : null}
                    <br/>
                    <Link to="/">Go home</Link>.
                </p>
            </div>
        </Layout>
    )
}

export default NotFoundPage
